import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

class Flash extends Component {
  constructor () {
    super()
    this.hideFlash = this.hideFlash.bind(this)
    this.state = { active: false }
  }

  hideFlash () {
    this.setState({ active: false })
    this.props.clearFlash && setTimeout(this.props.clearFlash, 500)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.message) {
      this.setState({ active: true })
      clearTimeout(this.hideTimer)
      this.hideTimer = setTimeout(this.hideFlash, 8000)
    }
  }

  render () {
    const { active } = this.state
    const {
      classNames,
      message
    } = this.props

    return (
      <div className={classNames.wrapper}>
        <div className={`${classNames.root} ${active ? classNames.active : classNames.hidden}`} onClick={this.hideFlash}>
          {message}
        </div>
      </div>
    )
  }
}

Flash.propTypes = {
  message: PropTypes.string,
  clearFlash: PropTypes.func
}

export default compose(
  withStyles(styles)
)(Flash)
