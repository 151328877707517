
export default (props, { mediaQuery, rhythm, scale }) => ({
  section: {
    root: {
      marginBottom: 0
    },
    header: {
      padding: 0,
      [mediaQuery('md')]: {
        padding: 0
      }
    },
    headerContent: {
      margin: 0
    }
  },
  small: {
    marginRight: rhythm(0.75),
    fontSize: scale(-1)
  }
})
