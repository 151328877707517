import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Heading from '../Heading'
import PoweredBy from '../PoweredBy'
import RichText from 'constructicon/rich-text'

const AuthForm = ({
  classNames,
  copy,
  footer,
  formComponent: Form,
  heading,
  poweredBy,
  styles
}) => (
  <div className={classNames.root}>
    {heading && <Heading>{heading}</Heading>}
    {copy && <RichText size={-1}>{copy}</RichText>}
    {poweredBy && <PoweredBy />}
    <Form
      formComponent={{
        styles,
        footer: footer && (
          <footer className={classNames.footer}>{footer}</footer>
        )
      }}
    />
  </div>
)

AuthForm.defaultProps = {
  poweredBy: true
}

AuthForm.propTypes = {
  formComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
    .isRequired
}

const mapState = ({ config }) => ({ ...config })

export default compose(
  connect(mapState),
  withStyles(styles)
)(AuthForm)
