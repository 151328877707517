import get from 'lodash/get'
import merge from 'lodash/merge'
import { stringify } from 'query-string'

const isEdh = child => child.fundraising_platform === 'everydayhero'

export const buildBaseDonateUrl = (child = {}, amount) => {
  const baseUrl = get(child, 'fundraising_page.donation_url', {})
  return isEdh(child) && amount ? [baseUrl, '/details'] : baseUrl
}

export const buildDonateUrl = (child = {}, selfDonate = true, amount) => {
  const basePath = typeof window !== 'undefined' ? window.location.origin : ''

  return [
    buildBaseDonateUrl(child, amount),
    stringify(
      merge(
        {},
        {
          amount: amount || undefined
        },
        !isEdh(child) && {
          exitUrl: `${basePath}/thank_you?donation_id=JUSTGIVING-DONATION-ID`,
          fundraiserName: child.firstname,
          reference: selfDonate ? child.id : undefined
        }
      )
    )
  ]
    .filter(Boolean)
    .join('?')
}
