import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { buildDonateUrl } from '../../../common/lib/donateUrl'
import * as traits from '../../../common/traits'

import Advertisements from '../../../common/components/Advertisements'
import AppSponsors from '../../../common/components/AppSponsors'
import BeneficiaryIntro from '../../../common/components/BeneficiaryIntro'
import Blocks from '../../../common/components/Blocks'
import ChildHeader from './components/ChildHeader'
import ChildIntro from '../../../common/components/ChildIntro'
import Container from 'constructicon/container'
import DonationBlock from './components/DonationBlock'
import Donations from './components/Donations'
import FundraisingSummary from '../../../common/components/FundraisingSummary'
import Header from './components/Header'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import TraitsProvider from 'constructicon/traits-provider'

class FundraisingPage extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount(){
    this.setState(
      {
        donateUrl: buildDonateUrl(this.props.child, this.props.loggedIn)
      }
    )
  }

  render () {
    const { advertisements, application, child, loggedIn, baseUrl } = this.props
    return (
      <TraitsProvider traits={traits}>
        <div id='mount'>
          <Header
            application={application}
            donateUrl={this.state.donateUrl}
            loggedIn={loggedIn}
          />
          <Container spacing={1} width={50}>
            <ChildHeader child={child} donateUrl={this.state.donateUrl} />
            <Grid spacing={0.5}>
              <GridColumn md={4}>
                <Blocks>
                  <ChildIntro
                    name={child.firstname}
                    story={child.story}
                  />
                  <BeneficiaryIntro charity={child.charity} />
                </Blocks>
              </GridColumn>
              <GridColumn md={8}>
                <Blocks>
                  <DonationBlock child={child} loggedIn={loggedIn} />
                  <FundraisingSummary
                    child={child}
                    showOffline={child.event.offline_donations_enabled}
                    showOnline
                  />
                  <Donations
                    application={application}
                    child={child}
                    page={child.fundraising_page}
                    baseUrl={baseUrl}
                  />
                  <Advertisements
                    advertisements={advertisements}
                    hidePrivateAds
                    columnWidths={{ md: 6 }}
                  />
                </Blocks>
              </GridColumn>
            </Grid>
          </Container>
          <AppSponsors application={application} />
        </div>
      </TraitsProvider>
    )
  }
}

FundraisingPage.propTypes = {
  child: PropTypes.object.isRequired
}

export default FundraisingPage
