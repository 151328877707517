import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const Message = ({
  classNames,
  children,
  show
}) => {
  if (!show) return null

  return (
    <div className={classNames.root}>
      {children}
    </div>
  )
}

export default withStyles(styles)(Message)
