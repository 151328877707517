export default (props, traits) => {
  const {
    calculateSpacing,
    colors,
    rhythm,
    scale
  } = traits

  const {
    margin = { l: 0.5 }
  } = props

  return {
    root: {
      ...calculateSpacing(margin, 'margin'),
      color: colors.primary,
      fontSize: scale(-1),
      fontWeight: 700,

      'svg': {
        marginRight: rhythm(0.25),
        verticalAlign: 'baseline'
      }
    }
  }
}
