import createReducer from '../../../../../common/lib/createReducer'
import { checkAccountAvailability, resetPassword, signIn, signUp } from '../../lib/justgiving'
import { post } from '../../../../../common/lib/request'
import { setFlash } from '../flash'

// Constants
const c = {
  FETCH: 'app/session/FETCH',
  SUCCESS: 'app/session/FETCH_SUCCESS',
  FAILURE: 'app/session/FETCH_FAILURE',
  EMAIL: 'app/session/FETCH_EMAIL'
}

const authorizeUser = (
  token,
  refreshToken,
  tokenExpiry,
  successMessage = 'Logged in successfully',
  newsletter = false
) => (dispatch, getState) => {
  const { config: { authorizeUrl } } = getState()

  return post(authorizeUrl, {
    token,
    refresh_token: refreshToken,
    token_expiry: tokenExpiry,
    newsletter
  })
    .then(data => {
      dispatch({ type: c.SUCCESS, payload: { data } })
      dispatch(setFlash(successMessage))
      setTimeout(() => window.location.assign(data.redirect_url), 500)
      return Promise.resolve(data)
    })
    .catch(error => {
      dispatch({ type: c.FAILURE, payload: { error } })
      return Promise.reject(error)
    })
}

// Action Creators
export const signupUser = data => dispatch => {
  return Promise.resolve()
    .then(() => dispatch({ type: c.FETCH }))
    .then(() => signUp(data))
    .then(res =>
      dispatch(
        authorizeUser(
          res.access_token,
          res.refresh_token,
          res.expires_at,
          'Registered successfully.',
          data.newsletter
        )
      )
    )
    .catch(error => {
      dispatch({ type: c.FAILURE, payload: { error } })
      return Promise.reject(error)
    })
}

export const loginUser = data => dispatch => {
  return Promise.resolve()
    .then(() => dispatch({ type: c.FETCH }))
    .then(() => signIn(data))
    .then(res => dispatch(
      authorizeUser(res.access_token, res.refresh_token, res.expires_at)
    ))
    .catch(error => {
      dispatch({ type: c.FAILURE, payload: { error } })
      return Promise.reject(error)
    })
}

export const sendPasswordResetEmail = data => dispatch => {
  return Promise.resolve()
    .then(() => dispatch({ type: c.FETCH }))
    .then(() => resetPassword(data))
    .then(() => {
      dispatch({ type: c.SUCCESS, payload: { data: {} } })
      return Promise.resolve({ email: data.email })
    })
    .catch(() => {
      dispatch({ type: c.SUCCESS, payload: { data: {} } })
      return Promise.resolve({ email: data.email })
    })
}

export const checkIfAccountExists = email => dispatch => {
  return Promise.resolve()
    .then(() => dispatch({ type: c.FETCH }))
    .then(() => checkAccountAvailability(email))
    .then(() => {
      dispatch({ type: c.EMAIL, payload: email })
      return true
    })
    .catch(() => {
      dispatch({ type: c.EMAIL, payload: email })
      return false
    })
}

// Action Handlers
const handlers = {
  [c.FETCH]: state => ({ ...state, status: 'fetching' }),
  [c.SUCCESS]: (state, { data }) => ({ status: 'fetched', ...data }),
  [c.FAILURE]: state => ({ ...state, status: 'failed' }),
  [c.EMAIL]: (state, email) => ({ ...state, status: 'fetched', email })
}

// Reducer
export default createReducer(handlers)
