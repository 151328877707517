export default (props, traits) => {
  const { colors, rhythm } = traits
  const { image } = props

  return {
    root: {
      position: 'relative',
      height: rhythm(1.5),
      width: rhythm(1.5),
      borderRadius: '50%',
      overflow: 'hidden',

      '> *:first-child': {
        height: '100%',
        width: '100%'
      },

      ':after': {
        content: !image && '"?"',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontWeight: 700,
        color: colors.primary
      }
    }
  }
}
