export default (props, traits) => {
  const { colors, rhythm } = traits

  return {
    root: {
      position: 'relative',
      zIndex: 6,
      backgroundColor: colors.light
    },

    container: {
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexPack: 'justify',
        paddingTop: rhythm(0.667),
        paddingBottom: rhythm(0.667),
        margin: '0 auto'
      }
    },

    logo: {
      display: 'block',
      height: rhythm(2.5),
      img: {
        display: 'block',
        maxHeight: '100%'
      }
    },

    link: {
      color: colors.dark,
      '&:hover': {
        color: colors.primary
      }
    }
  }
}
