export default (props, traits) => {
  const {
    rhythm,
    scale,
    colors
  } = traits

  return {
    root: {
      display: 'flex',
      width: '100%',
      marginBottom: rhythm(1)
    },
    input: {
      flex: 1,
      padding: rhythm(0.5),
      fontSize: scale(-1),
      border: `thin solid ${colors.lightGrey}`,
      borderRightWidth: 0
    }
  }
}
