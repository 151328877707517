export default (props, traits) => {
  const {
    disabled,
    styles,
    whiteSpace
  } = props

  return {
    root: {
      opacity: disabled && 0.5,
      cursor: disabled && 'not-allowed',
      whiteSpace: whiteSpace,
      ...styles
    }
  }
}
