import React from 'react'
import currentApplication from '../../../common/lib/currentApplication'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Container from 'constructicon/container'

const Sponsors = ({
  application = currentApplication,
  classNames,
  styles
}) => {
  const { sponsors = [] } = application

  return (
    <Container width={48} spacing={1} styles={styles.container}>
      {sponsors.map((sponsor, i) => (
        <a key={i} href={sponsor.url} target='_blank' rel='noopener noreferrer' className={classNames.sponsor} title={sponsor.name}>
          <img src={sponsor.logo_url} title={sponsor.name} className={classNames.logo} />
        </a>
      ))}
    </Container>
  )
}

export default withStyles(styles)(Sponsors)
