import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Heading from 'constructicon/heading'

const CustomHeading = ({ styles, ...props }) => (
  <Heading {...props} styles={styles.root} />
)

CustomHeading.defaultProps = {
  size: 1,
  spacing: { b: 1 }
}

export default withStyles(styles)(CustomHeading)
