export default (props, traits) => {
  const { colors, rhythm, scale } = traits

  return {
    root: {
      position: 'relative'
    },

    message: {
      position: 'absolute',
      zIndex: 6,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: rhythm(10),
      backgroundColor: colors.light,
      padding: rhythm([1]),
      fontSize: scale(-0.5),
      textAlign: 'center',
      boxShadow: '0 0 25px rgba(0,0,0,0.25)'
    },

    spin: {
      display: 'inline-block',
      width: rhythm(0.75),
      height: rhythm(0.75),
      borderRadius: '50%',
      border: '2px solid transparent',
      borderRightColor: colors.dark,
      textIndent: '-9999px',
      overflow: 'hidden',
      animation: 'spin 1s linear infinite',

      '@keyframes spin': {
        '0%': {
          transform: 'rotate(0deg)'
        },
        '100%': {
          transform: 'rotate(360deg)'
        }
      }
    }
  }
}
