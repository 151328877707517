import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import Heading from 'constructicon/heading'

const ThickHeading = ({ styles, ...props }) => (
  <Heading
    styles={styles.root}
    {...props}
  />
)

export default withStyles(styles)(ThickHeading)
