import * as validators from 'constructicon/lib/validators'

export default ({ child }) => ({
  fields: {
    cashAmount: {
      type: 'number',
      label: 'Cash Amount',
      step: 0.01,
      initial: parseFloat(child.offline_amount_in_cents / 100).toFixed(2),
      validators: [
        validators.required('Cash amount is required'),
        validators.number('Cash amount must be a number'),
        (v) => parseFloat(v) < 0 && 'Cash amount can\'t be negative',
        (v) => parseFloat(v) > 15000 && 'Cash amount is too large, please enter the correct amount'
      ]
    }
  }
})
