import React from 'react'

import AuthForm from '../../components/AuthForm'
import { Link } from 'react-router'
import LoginForm from '../../components/LoginForm'

const Login = () => (
  <AuthForm
    heading='Parent/Guardian Login'
    formComponent={LoginForm}
    footer={(
      <span>
        <Link to='/sign_up'>Need an account?</Link>{' '}|{' '}
        <Link to='/sign_in/reset_password'>Forgot your password?</Link>
      </span>
    )}
  />
)

export default Login
