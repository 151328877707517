import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { checkIfAccountExists } from '../../store/session'
import withForm from 'constructicon/with-form'
import form from './form'

import Form from '../../../../../common/components/Form'
import InputField from '../../../../../common/components/InputField'
import LoadingMessage from '../../../../../common/components/LoadingMessage'
import Message from '../Message'

class CheckAccount extends React.Component {
  constructor () {
    super()
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = { errors: [] }
  }

  handleSubmit (e) {
    e.preventDefault()

    const { checkIfAccountExists, form, router } = this.props

    return form
      .submit()
      .then(data => checkIfAccountExists(data.email))
      .then(exists => exists
        ? router.push('/sign_in?message=true')
        : router.push('/sign_up?message=true'))
  }

  render () {
    const {
      form,
      formComponent,
      status
    } = this.props

    return (
      <LoadingMessage
        message='Checking your account, this may take a few seconds'
        status={status}>
        <Form
          isLoading={status === 'fetching'}
          onSubmit={this.handleSubmit}
          errors={this.state.errors}
          noValidate
          {...formComponent}
        >
          <Message show>
            Our online fundraising pages are now powered by JustGiving. To participate you will need to create a new account, or login to an existing account. Let's check if you have a JustGiving account.
          </Message>
          <InputField {...form.fields.email} />
        </Form>
      </LoadingMessage>
    )
  }
}

const mapStateToProps = state => ({ status: state.session.status })
const mapDispatchToProps = { checkIfAccountExists }

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withForm(form)
)(CheckAccount)
