export default props => ({
  fields: {
    amount: {
      type: 'number',
      validators: [
        val => !parseInt(val) && 'Please enter an amount',
        val => parseInt(val) < 0 && 'Please enter a positive amount'
      ]
    }
  }
})
