export default `
# Privacy Policy

[www.schoolfundraising.co.nz](https://www.schoolfundraising.co.nz)<br />

This privacy policy has been compiled to better serve those who are concerned with how their 'Personally Identifiable Information' (PII) is being used online. PII, as described in privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.

### What personal information do we collect from the people that visit our blog, website or app?

When registering on our site, as appropriate, you may be asked to enter your name, email address, phone number or other details to help you with your experience.

### When do we collect information?

We collect information from you when you register on our site, subscribe to a newsletter, respond to a survey, fill out a form or enter information on our site.

### How do we use your information?

We may use the information we collect from you when you register, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:
To personalise your experience and to allow us to deliver the type of content and product offerings in which you are most interested

- To improve our website to better serve you
- To allow us to better service you in responding to your customer service requests
- To administer a contest, promotion, survey or other site feature
- To ask for ratings and reviews of services or products
- To follow up with you after correspondence (live chat, email or phone inquiries)
- To stay connected with your family via monthly e-newsletters
- To help you throughout your fundraising experience via tutorial emails
- To keep you updated on this fundraiser via general news emails

### We collect your email address in order to:

- Send information, respond to inquiries, and/or other requests or questions
- Send automated emails with hints and tips plus alerts during your School Fun-Run
- Send you monthly emails to keep you up to date with the School Fun-Run
- Process orders and to send information and updates pertaining to orders
- Send you additional information related to your product and/or service
- Market to our mailing list or continue to send emails to our clients after the original transaction has occurred

### We agree to the following:

- Not use false or misleading subjects or email addresses
- Identify the message as an advertisement in some reasonable way
- Include the physical address of our business or site headquarters
- Monitor third-party email marketing services for compliance, if one is used
- Honor opt-out/unsubscribe requests quickly
- Allow users to unsubscribe by using the link at the bottom of each email
- Not sell or give your email address to third party organisations not involved directly with the School Fun-Run
- If at any time you would like to unsubscribe from receiving future emails, you can email us at ben@schoolfundraising.co.nz and we will promptly remove you from ALL correspondence.

### Third-party disclosure

We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. Such parties may include everydayhero, and Webjet. We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property or safety.

### Third-party links

Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.

### How do we protect your information?

Your personal information is contained behind secured networks in Australia. We follow the Industry best practices to secure your data. Our servers are protected by firewalls. We use strong encryption mechanism when conducting routine maintenance to the application or to store sensitive information such as passwords. We constantly keep the application’s dependencies up to date with the latest security updates.

### Do we use 'cookies'?

Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information.

### We use cookies to:

We use a single cookie after signing in to the application. This cookie holds a strongly encrypted session ID which allows to remember which user is currently logged in. When signing out, the cookie’s content is removed.

### Google

Google's advertising requirements can be summed up by Google's Advertising Principles. They are put in place to provide a positive experience for users. https://support.google.com/adwordspolicy/answer/1316548?hl=en
We use Google AdSense Advertising on our website.
Google, as a third-party vendor, uses cookies to serve ads on our site. Google's use of the DART cookie enables it to serve ads to our users based on previous visits to our site and other sites on the Internet. Users may opt-out of the use of the DART cookie by visiting the Google Ad and Content Network privacy policy.
We have implemented the following:

- Remarketing with Google AdSense
- Google Display Network Impression Reporting
- Demographics and Interests Reporting

We, along with third-party vendors such as Google use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions with ad impressions and other ad service functions as they relate to our website.
Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the first significant page after entering our website.
Our Privacy Policy link includes the word 'Privacy' and can easily be found on the page specified above.

You will be notified of any Privacy Policy changes:

- On our Privacy Policy Page

Can change your personal information:

- By emailing us
- By calling us
- By logging in to your account

### Contacting Us

If there are any questions regarding this privacy policy, you may contact us using the information below.

Privacy Officer <br />
PO Box 74443 Greenlane, AUCKLAND 1546 <br />
ben@schoolfundraising.co.nz <br />
09 377 1782 <br />
www.schoolfundraising.co.nz <br />

Last Edited on 09-05-2018
`
