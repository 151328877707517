export default (props, traits) => {
  const { nowrap } = props

  const {
    rhythm,
    scale,
    colors,
    treatments
  } = traits

  return {
    date: {
      color: colors.dark,
      marginTop: rhythm(0.25),
      marginBottom: rhythm(0.25),
      textAlign: 'center'
    },

    label: {
      marginBottom: rhythm(0.25),
      ...treatments.head,
      fontSize: scale(-0.5),
      whiteSpace: nowrap ? 'nowrap' : 'initial'
    },

    calendar: {
      width: rhythm(4.5),
      margin: 'auto',
      padding: `${rhythm(0.5)} ${rhythm(0.25)}`,
      border: `2px solid ${colors.dark}`,
      lineHeight: 1
    },

    month: {
      color: colors.secondary,
      textTransform: 'uppercase',
      fontSize: scale(0.75),
      fontWeight: 700
    },

    day: {
      paddingTop: rhythm(0.25),
      fontSize: scale(6),
      ...treatments.thick
    },

    year: {
      paddingTop: rhythm(0.25),
      fontSize: scale(-1.5)
    }
  }
}
