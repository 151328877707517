import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Button from '../../../common/components/Button'
import ButtonGroup from 'constructicon/button-group'
import Section from 'constructicon/section'
import Heading from 'constructicon/heading'
import Info from '../Info'
import Icon from '../Icon'

const SectionBlock = ({
  heading,
  children,
  info,
  buttons = [],
  header,
  classNames,
  styles
}) => (
  <Section styles={styles.root}>
    <header className={classNames.header}>
      <div className={classNames.headerContent}>
        {heading && (
          <div>
            <Heading tag='h3' styles={styles.heading}>
              {heading}
            </Heading>
          </div>
        )}
        {buttons.length ? (
          <ButtonGroup styles={styles.buttons} align='center'>
            {buttons.map(({
              label,
              icon,
              theme = 'transparentLight',
              ...buttonProps
            }, i) => (
              <Button
                key={i}
                theme={theme}
                styles={styles.button}
                {...buttonProps}
              >
                <span>{label}</span>
                {icon && <Icon name={icon} />}
              </Button>
            ))}
          </ButtonGroup>
        ) : null}
        {info && <Info>{info}</Info>}
        {header}
      </div>
    </header>
    <article className={classNames.body}>{children}</article>
  </Section>
)

export default withStyles(styles)(SectionBlock)
