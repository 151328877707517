import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import Icon from '../Icon'

const StateError = ({
  classNames,
  styles
}) => (
  <div className={classNames.root}>
    <Icon name='warning' size={3} />
    <div className={classNames.label}>There was an unexpected error</div>
  </div>
)

export default withStyles(styles)(StateError)
