import merge from 'lodash/merge'

export default (props, traits) => {
  const {
    colors,
    measures,
    radiuses,
    rhythm,
    scale,
    treatments,
    mediaQuery
  } = traits

  const baseStyles = {
    form: {
      root: {
        paddingBottom: 0
      },

      actions: {
        display: 'flex',
        flexDirection: 'row-reverse',
        flexWrap: 'wrap',
        justifyContent: 'center',
        [mediaQuery('sm')]: {
          justifyContent: 'flex-start'
        }
      },

      action: {
        padding: `${rhythm(0.333)} ${rhythm(0.75)}`,
        border: `2px solid ${colors.mediumGrey}`,
        color: colors.dark,
        fontSize: scale(-1)
      },

      submit: {
        padding: `${rhythm(0.333)} ${rhythm(0.75)}`,
        border: `2px solid ${colors.primary}`,
        backgroundColor: colors.primary,
        fontSize: scale(-1)
      }
    },

    error: {
      padding: `${rhythm(0.25)} ${rhythm(0.5)}`,
      margin: rhythm([0.5, 0]),
      borderRadius: radiuses.small,
      backgroundColor: colors.danger,
      color: colors.light,
      lineHeight: measures.medium,
      fontSize: scale(-1),
      ...treatments.body,
      a: {
        color: colors.light,
        textDecoration: 'underline'
      }
    }
  }

  return merge(baseStyles, props.styles)
}
