import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Form from 'constructicon/form'

const CustomForm = ({ children, classNames, errors, styles, ...props }) => (
  <Form styles={styles.form} {...props}>
    {errors &&
      errors.map((error, index) => (
        <div key={index} className={classNames.error}>
          {error}
        </div>
      ))}
    {children}
  </Form>
)

export default withStyles(styles)(CustomForm)
