export default (props, { colors, radiuses, rhythm }) => ({
  section: {
    root: {
      marginBottom: 0
    }
  },
  termsWrapper: {
    padding: rhythm(),
    border: `1px solid ${colors.lightGrey}`,
    marginBottom: rhythm(1),
    overflowX: 'scroll',
    height: 'calc(100vh - 17em)',
    minHeight: '10em',

    '*': {
      textAlign: 'left !important',
      margin: '1em 0',
      paddingBottom: '0 !important'
    },

    'a': {
      color: colors.primary,
      fontWeight: 'bold'
    },

    'li': {
      margin: '0 0 0 2em !important'
    }
  }
})
