import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Heading from 'constructicon/heading'
import Section from 'constructicon/section'
import RichText from 'constructicon/rich-text'

const BeneficiaryIntro = ({ charity = {}, styles }) => {
  const logo = charity.logo_url || charity.logo_absolute_url

  if (charity.name) {
    return (
      <Section
        background='light'
        tag='div'>
        <Heading
          size={1.5}
          styles={styles.heading}
          spacing={{ b: 1 }}>
          {logo && <img alt={charity.name} src={logo} />}
          {charity.name}
        </Heading>
        <RichText size={-0.5}>
          <p>{charity.description}</p>
        </RichText>
      </Section>
    )
  }

  return null
}

export default withStyles(styles)(BeneficiaryIntro)
