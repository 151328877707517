import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import Modal from 'constructicon/modal'

const CustomModal = ({ classNames, styles, id, contentLabel, ...props }) => {
  const bodyClassName = `ReactModal__Body--open${id ? `--${id}` : ''}`
  return (
    <Modal
      styles={styles.root}
      shouldCloseOnOverlayClick={false}
      bodyOpenClassName={bodyClassName}
      contentLabel={contentLabel || 'Modal'}
      {...props}
    />
  )
}

export default withStyles(styles)(CustomModal)
