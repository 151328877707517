import merge from 'lodash/merge'

export default ({ styles }, traits) => ({
  root: merge(
    {
      fontWeight: 900,
      letterSpacing: 0,
      textTransform: 'uppercase'
    },
    styles
  )
})
