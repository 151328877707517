import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { sendPasswordResetEmail } from '../../store/session'
import withForm from 'constructicon/with-form'
import form from './form'

import Button from '../../../../../common/components/Button'
import Container from 'constructicon/container'
import Form from '../../../../../common/components/Form'
import InputField from '../../../../../common/components/InputField'
import LoadingMessage from '../../../../../common/components/LoadingMessage'
import { Link } from 'react-router'
import RichText from 'constructicon/rich-text'

class ResetPasswordForm extends Component {
  constructor () {
    super()
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = { errors: [], status: '' }
  }

  handleSubmit (e) {
    e.preventDefault()

    const {
      baseUrl,
      form,
      sendPasswordResetEmail
    } = this.props

    return form.submit().then(({ email }) => {
      sendPasswordResetEmail({
        email,
        returnTo: `${baseUrl}users/sign_in`
      })

      this.setState({ status: 'sending' }, () =>
        setTimeout(() => {
          form.resetForm()
          this.setState({ status: 'sent' })
        }, 950)
      )
    })
  }

  render () {
    const { form, formComponent } = this.props
    const { status } = this.state

    switch (status) {
      case 'sent':
        return (
          <div>
            <p>
              If an account exists with this email address, you will receive an
              email within the next few minutes. It contains instructions for
              changing your password.
            </p>
            <Container spacing={{ t: 0.5, b: 1 }}>
              <Button
                theme='secondary'
                onClick={() => this.setState({ status: 'empty' })}
              >
                Send to a different email
              </Button>
            </Container>
            <RichText size={-1} spacing={{ t: 1 }}>
              <Link to='/sign_in'>Login to your account</Link>
            </RichText>
          </div>
        )
      default:
        return (
          <LoadingMessage
            message='Sending you a password reset email'
            status={status}>
            <Form
              isLoading={status === 'sending'}
              onSubmit={this.handleSubmit}
              errors={this.state.errors}
              submit='Send Instructions'
              noValidate
              {...formComponent}
            >
              <InputField {...form.fields.email} />
            </Form>
          </LoadingMessage>
        )
    }
  }
}

const mapState = ({ config, session }) => ({
  ...config,
  status: session.status
})

const mapActions = { sendPasswordResetEmail }

export default compose(
  connect(mapState, mapActions),
  withForm(form)
)(ResetPasswordForm)
