import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { clearFlash } from '../../store/flash'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Flash from '../../../../../common/components/Flash'
import Section from '../../../../../common/components/Section'
import Tabs from '../../components/Tabs'
import Wrapper from '../../components/Wrapper'

class App extends Component {
  render () {
    const { children, clearFlash, flash = {}, styles } = this.props

    return (
      <Wrapper>
        <Section styles={styles.section} header={<Tabs />}>
          <div>{children}</div>
          <Flash {...flash} clearFlash={clearFlash} />
        </Section>
      </Wrapper>
    )
  }
}

const mapState = ({ flash }) => ({ flash })
const mapActions = { clearFlash }

export default compose(
  withStyles(styles),
  connect(mapState, mapActions)
)(App)
