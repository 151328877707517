export default (props, { colors, mediaQuery, scale, rhythm, transitions }) => ({
  root: {
    display: 'flex',
    margin: 0,
    flex: 1,
    textAlign: 'center'
  },
  link: {
    flex: 1,
    padding: rhythm([0.75, 1]),
    fontSize: scale(0.5),
    backgroundColor: colors.primaryLight,
    color: colors.primaryDark,
    textTransform: 'uppercase',
    fontWeight: 900,
    transition: transitions.easeOut,
    ':hover': {
      boxShadow: 'inset 0 0 100rem rgba(255, 255, 255, 0.2)'
    },
    ':first-of-type': {
      borderRight: '1px solid rgba(0,0,0,0.15)'
    },
    [mediaQuery('sm')]: {
      padding: rhythm([1, 1]),
      fontSize: scale(1)
    }
  },
  active: {
    backgroundColor: 'transparent',
    color: colors.light,
    ':hover': {
      boxShadow: 'none'
    }
  }
})
