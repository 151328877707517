import { rhythm } from 'constructicon/lib/traits'

export const radiuses = {
  small: '0.125rem',
  medium: '0.25rem',
  large: '0.5rem',
  round: '50rem'
}

export const breakpoints = {
  xxs: '28rem',
  xs: '32rem',
  sm: '46em',
  md: '60em',
  lg: '96rem'
}

export const shadows = {
  light: '0 0 15px rgba(0,0,0,0.1)'
}

export const colors = {
  primary: '#f57222',
  secondary: '#16b7c7',
  tertiary: '#cfdd28',

  primaryLight: '#ff8534',
  primaryShade: '#fdf1e0',
  secondaryTint: '#37C8D6',
  secondaryLight: '#51cedc',
  secondaryLighter: '#b1e7ec',
  secondaryShade: '#e1f5f7',

  primaryDark: '#B75519',
  secondaryDark: '#108995',

  dark: '#222222',
  darkGrey: '#888888',
  mediumGrey: '#a1a1a1',
  lightGrey: '#dbdbdb',
  paleGrey: '#e0e0e0',
  offWhite: '#f8f8f8',

  gold: '#c89b54',
  brown: '#3b1f03',

  notice: '#fdf6e7',
  danger: '#ed2b2a',
  success: '#cfdd28',
  warning: '#ff9933',

  shadeDark: 'rgba(0,0,0,0.075)',
  shade: 'rgba(0,0,0,0.05)',
  shadeLight: 'rgba(0,0,0,0.025)'
}

export const fonts = {
  head: 'Montserrat, sans-serif',
  body: 'Montserrat, sans-serif',
  button: 'Montserrat, sans-serif'
}

export const treatments = {
  head: {
    fontFamily: fonts.head,
    fontWeight: 700
  },
  thick: {
    fontFamily: fonts.head,
    fontWeight: 900
  },
  body: {
    fontFamily: fonts.body,
    fontWeight: 400
  },
  button: {
    fontFamily: fonts.button,
    fontWeight: 700,
    textTransform: 'none',
    borderRadius: 0,
    'svg:first-child': {
      marginRight: rhythm(0.5)
    },
    'svg:last-child': {
      marginLeft: rhythm(0.5)
    }
  },
  label: {
    fontFamily: fonts.head,
    fontWeight: 500,
    lineHeight: 1.4,
    textTransform: 'uppercase',
    letterSpacing: '0.05em'
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
}
