import merge from 'lodash/merge'

export default (props, traits) => {
  const { colors, measures, radiuses, rhythm, scale, treatments } = traits

  const { error, styles } = props

  const baseStyles = {
    input: {
      lineHeight: '100%',
      ...treatments.body,
      ':focus': {
        boxShadow: `0 0 5px ${error ? colors.red : colors.secondary}`
      }
    },

    field: {
      boxShadow: 'none',
      borderRadius: radiuses.small,
      ...treatments.body,

      '::-webkit-input-placeholder': { opacity: 0.25 },
      '::-moz-placeholder': { opacity: 0.25 },
      ':-ms-input-placeholder': { opacity: 0.25 },
      ':-moz-placeholder': { opacity: 0.25 }
    },

    label: {
      marginTop: 0,
      marginBottom: rhythm(0.5),
      fontSize: scale(-2),
      ...treatments.label
    },

    icon: {
      zIndex: 1,
      pointerEvents: 'none'
    },

    error: {
      div: {
        padding: `${rhythm(0.25)} ${rhythm(0.5)}`,
        borderRadius: radiuses.small,
        backgroundColor: colors.danger,
        color: colors.light,
        lineHeight: measures.medium,
        ...treatments.body
      }
    }
  }

  return merge(baseStyles, styles)
}
