export default (props, traits) => {
  const { colors, rhythm, scale } = traits

  return {
    root: {
      backgroundColor: colors.shade,
      padding: rhythm([0.5, 1]),
      marginBottom: rhythm(1),
      fontSize: scale(-0.5),
      textAlign: 'left'
    }
  }
}
