import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const Loading = ({
  classNames
}) => (
  <div className={classNames.wrapper}>
    <span className={classNames.dot} />
    <span className={classNames.dot} />
    <span className={classNames.dot} />
  </div>
)

export default withStyles(styles)(Loading)
