export default (props, traits) => {
  const { calculateSpacing, rhythm } = traits
  const { spacing } = props

  return {
    root: {
      ...calculateSpacing(spacing, 'margin'),
      display: 'block',
      ' > * + *': {
        marginTop: rhythm(1)
      }
    }
  }
}
