import merge from 'lodash/merge'

export default (props, traits) => {
  const {
    colors,
    mediaQuery,
    rhythm,
    scale,
    treatments
  } = traits

  const {
    color = 'secondary',
    right,
    small
  } = props

  const baseStyles = {
    root: {
      textAlign: 'center',
      marginTop: rhythm(0.25),
      marginBottom: rhythm(0.25),
      [mediaQuery('sm')]: {
        marginLeft: right && 'auto',
        textAlign: right ? 'right' : 'left'
      }
    },

    label: {
      marginBottom: small ? rhythm(0.875) : rhythm(0.5),
      fontSize: scale(small ? 0 : 1),
      fontWeight: 700
    },

    value: {
      ...treatments.head,
      lineHeight: 1,
      fontSize: scale(small ? 3 : 5),
      fontWeight: small ? 400 : 800,
      color: colors[color]
    },

    caption: {
      display: 'block',
      fontSize: scale(-2),
      marginTop: rhythm(0.25),
      lineHeight: 1.5,

      a: {
        color: colors[color],
        textDecoration: 'underline'
      }
    }
  }

  return merge(baseStyles, props.styles)
}
