export default (props, { colors, rhythm, scale }) => ({
  container: {
    root: {
      textAlign: 'center'
    }
  },
  logo: {
    display: 'block',
    maxWidth: rhythm(10),
    margin: 'auto',
    img: {
      display: 'block',
      maxWidth: '100%'
    }
  },
  card: {
    background: colors.light,
    padding: rhythm(1.5),
    margin: rhythm([1, 0])
  },
  title: {
    margin: `0 auto`,
    maxWidth: rhythm(18),
    fontSize: scale(2),
    lineHeight: '1.25em'
  },
  avatar: {
    display: 'block',
    maxWidth: rhythm(10),
    margin: `${rhythm(1)} auto ${rhythm(0.5)}`
  },
  info: {
    display: 'block',
    margin: `${rhythm(1)} auto 0`,
    maxWidth: rhythm(18),
    fontSize: scale(-1.25),
    lineHeight: '1.5em'
  },
  details: {
    dd: {
      marginBottom: rhythm(0.5),
      fontWeight: 'bold'
    }
  },
  processing: {
    backgroundColor: colors.secondary,
    color: colors.light,
    padding: rhythm([0.5, 1]),
    marginTop: rhythm(1),
    fontSize: scale(-1)
  }
})
