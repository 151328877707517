import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const Banner = ({
  childStickers = [],
  classNames
}) => (
  <div className={classNames.root}>
    {childStickers.map(childSticker => (
      childSticker.banner_x_percent ? (
        <img
          alt={childSticker.sticker.name}
          className={classNames.sticker}
          key={childSticker.id}
          src={childSticker.sticker.image_url}
          style={{
            top: `${childSticker.banner_y_percent}%`,
            left: `${childSticker.banner_x_percent}%`
          }}
        />
      ) : null
    ))}
  </div>
)

export default withStyles(styles)(Banner)
