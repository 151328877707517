import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const LoadingMessage = ({
  children,
  classNames,
  message,
  status
}) => (
  <div className={classNames.root}>
    {children}
    {status === 'fetching' && (
      <div className={classNames.message}>
        <span className={classNames.spin} />
        <div>{message}</div>
      </div>
    )}
  </div>
)

export default withStyles(styles)(LoadingMessage)
