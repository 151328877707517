import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const Tooltip = ({
  classNames,
  children,
  info
}) => (
  <span className={classNames.root}>
    <div className={classNames.tooltip}>{info}</div>
    {children}
  </span>
)

export default withStyles(styles)(Tooltip)
