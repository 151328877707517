import createReducer from '../../../../../common/lib/createReducer'

// Constants
const c = {
  SET: 'app/flash/SET',
  CLEAR: 'app/flash/CLEAR'
}

// Action Creators
export const clearFlash = () => ({
  type: c.CLEAR
})

export const setFlash = (message, danger) => ({
  type: c.SET,
  payload: { message, danger }
})

// Action Handlers
const handlers = {
  [c.SET]: (state, { message, danger }) => ({ message, danger }),
  [c.CLEAR]: (state) => ({})
}

// Reducer
export default createReducer(handlers)
