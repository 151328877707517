export default (props, traits) => {
  const { banner } = props
  const { rhythm } = traits

  return {
    root: {
      position: 'relative',
      background: `url(${banner}) no-repeat center`,
      backgroundSize: 'cover',
      paddingBottom: '25%'
    },

    sticker: {
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      width: '9vw',
      maxWidth: rhythm(3)
    }
  }
}
