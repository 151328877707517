import React from 'react'

import AuthForm from '../../components/AuthForm'
import { Link } from 'react-router'
import SignupForm from '../../components/SignupForm'

const Signup = () => (
  <AuthForm
    heading='Parent/Guardian Registration'
    formComponent={SignupForm}
    footer={<Link to='/sign_in'>Already have an account?</Link>}
  />
)

export default Signup
