import React from 'react'
import { connect } from 'react-redux'

import AuthForm from '../../components/AuthForm'
import CheckAccount from '../../components/CheckAccount'

const Introduction = ({ config }) => (
  <AuthForm
    heading={`Welcome to ${config.application.application_title}`}
    formComponent={CheckAccount}
  />
)

const mapStateToProps = state => ({
  config: state.config
})

export default connect(mapStateToProps)(Introduction)
