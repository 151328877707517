export default (props, traits) => {
  const { rhythm } = traits

  return {
    root: {
      content: {
        borderRadius: 0,
        borderWidth: 0,
        maxWidth: rhythm(18)
      }
    },

    title: {
      margin: 0,
      paddingTop: rhythm(0.25),
      paddingBottom: rhythm(0.25)
    }
  }
}
