import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import InputDate from '../InputDate'
import InputField from 'constructicon/input-field'
import InputSelect from 'constructicon/input-select'

const Input = ({
  classNames,
  styles,
  type,
  ...props
}) => {
  switch (type) {
    case 'select':
      return <InputSelect styles={styles} {...props} />
    case 'date':
      return <InputDate styles={styles} {...props} />
    default:
      return <InputField type={type} styles={styles} {...props} />
  }
}

export default withStyles(styles)(Input)
