export default (props, traits) => {
  const { rhythm, colors, scale, radiuses } = traits
  return {
    root: {
      position: 'absolute',
      zIndex: 12,
      top: '50%',
      width: rhythm(1.5),
      height: rhythm(1.5),
      lineHeight: rhythm(1.5),
      marginTop: rhythm(-0.75),
      right: rhythm(1),
      borderRadius: '50%',
      border: `2px solid ${colors.light}`,
      textAlign: 'center',
      cursor: 'help',
      transition: 'all 200ms ease',
      ':hover': {
        background: 'rgba(255,255,255,0.25)'
      },
      ':hover div': {
        transform: 'translateY(0.5em)',
        opacity: 1,
        visiblity: 'visible',
        pointerEvents: 'all',
        cursor: 'default'
      },
      svg: {
        marginTop: rhythm(-0.25)
      }
    },

    text: {
      right: rhythm(-0.5),
      left: 'auto',
      transform: 'translateY(0)',
      visiblity: 'hidden',
      opacity: 0,
      pointerEvents: 'none',
      position: 'absolute',
      top: '100%',
      marginTop: rhythm(0.5),
      lineHeight: '1.333em',
      background: colors.light,
      color: colors.dark,
      padding: rhythm(0.5),
      minWidth: rhythm(8),
      fontSize: scale(-1),
      borderRadius: radiuses.medium,
      boxShadow: `0 1px 5px rgba(0,0,0,0.25)`,
      transition: 'all 200ms ease',
      ':before': {
        content: '""',
        position: 'absolute',
        left: 'auto',
        top: rhythm(-0.333),
        right: rhythm(0.8),
        background: colors.light,
        width: rhythm(0.666),
        height: rhythm(0.666),
        marginLeft: rhythm(-0.333),
        transform: 'rotate(45deg)'
      },
      ':after': {
        content: '""',
        position: 'absolute',
        top: rhythm(-2),
        left: rhythm(-2),
        bottom: rhythm(-2),
        right: rhythm(-2),
        zIndex: -1
      }
    }
  }
}
