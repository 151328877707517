import React, { Component } from 'react'
import withForm from 'constructicon/with-form'
import form from './form'

import EditModal from '../../../common/components/EditModal'
import Form from '../../../common/components/Form'
import InputCurrency from '../../../common/components/InputCurrency'

class EditCashModal extends Component {
  constructor () {
    super()
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      errors: [],
      status: null
    }
  }

  handleSubmit (e) {
    e.preventDefault()

    const {
      form,
      onSubmit,
      onSuccess
    } = this.props

    Promise.resolve()
      .then(() => this.setState({ status: 'fetching' }))
      .then(() => form.submit())
      .then(data => onSubmit({ offline_amount_in_cents: Math.round(data.cashAmount * 100) }))
      .then(() => this.setState({ status: 'fetched' }))
      .then(() => onSuccess())
      .catch(error => {
        this.setState({ status: 'failed', errors: [ 'Please correct the invalid fields before submitting.' ] })
        return Promise.reject(error)
      })
  }

  render () {
    const {
      errors,
      status
    } = this.state

    const {
      form,
      onClose
    } = this.props

    return (
      <EditModal
        onClose={onClose}
        open
        title='Edit Cash Amount'>
        <Form
          onSubmit={this.handleSubmit}
          submit='Update'
          actions={[{ label: 'Cancel', onClick: onClose }]}
          isLoading={status === 'fetching'}
          errors={errors}
          noValidate>
          <InputCurrency {...form.fields.cashAmount} />
        </Form>
      </EditModal>
    )
  }
}

export default withForm(form)(EditCashModal)
