import React from 'react'
import Icon from '../Icon'
import styles from './styles'
import withStyles from 'constructicon/with-styles'

const Info = ({ children, classNames }) => (
  <div className={classNames.root}>
    <Icon name='info' />
    <div className={classNames.text}>{children}</div>
  </div>
)

export default withStyles(styles)(Info)
