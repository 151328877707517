export default (props, traits) => {
  const {
    colors,
    rhythm
  } = traits

  const { size = 2 } = props
  const dotSize = size / 6

  return {
    root: {
      height: rhythm(dotSize)
    },

    dot: {
      position: 'relative',
      display: 'inline-block',
      margin: `auto ${rhythm(0.33)}`,
      width: rhythm(dotSize),
      height: rhythm(dotSize),
      backgroundColor: colors.mediumGrey,
      lineHeight: 0,
      transformOrigin: 'bottom',
      borderRadius: '50%',
      animation: 'dots 600ms infinite alternate',

      '@keyframes dots': {
        '0%': {
          opacity: 0.9,
          transform: 'scale(1)'
        },
        '100%': {
          opacity: 0.1,
          transform: 'scale(1.5)'
        }
      },
      ':nth-of-type(2)': {
        animationDelay: '200ms'
      },
      ':nth-of-type(3)': {
        animationDelay: '400ms'
      }
    }
  }
}
