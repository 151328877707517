import React from 'react'
import InputField from '../InputField'

const InputCurrency = ({
  onBlur,
  ...props
}) => (
  <InputField
    {...props}
    onBlur={(val = 0) => onBlur(parseFloat(val).toFixed(2))}
  />
)

export default InputCurrency
