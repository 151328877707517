import React from 'react'
import numbro from 'numbro'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import Tooltip from '../Tooltip'

const Metric = ({
  children,
  label,
  format,
  caption,
  classNames,
  tooltip
}) => {
  const value = parseFloat(children) ? numbro(children).format(format) : children
  const Tag = tooltip ? Tooltip : 'div'
  const tagProps = tooltip ? { info: tooltip, textAlign: 'left', fontScale: -1.5, verticalOffset: 1 } : {}

  return (
    <div className={classNames.root}>
      <Tag {...tagProps}>
        {label && <label className={classNames.label}>{label}</label>}
        <div className={classNames.value}>
          {value || numbro(0).format(format)}
          {caption && <small className={classNames.caption}>{caption}</small>}
        </div>
      </Tag>
    </div>
  )
}

Metric.defaultProps = {
  format: '$0,0.00'
}

export default withStyles(styles)(Metric)
