import React from 'react'
import StateEmpty from '../StateEmpty'
import StateError from '../StateError'
import StateLoading from '../StateLoading'

const Status = ({
  children,
  empty,
  emptyMessage,
  status
}) => {
  if (status === 'fetching') {
    return <StateLoading />
  }

  if (status === 'failed') {
    return <StateError />
  }

  if (empty) {
    return <StateEmpty message={emptyMessage} />
  }

  return <div>{children}</div>
}

export default Status
