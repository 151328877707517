export default (props, traits) => {
  const {
    colors,
    rhythm,
    scale,
    treatments
  } = traits

  return {
    root: {
      fontSize: scale(-0.5),
      borderBottom: `1px solid ${colors.shade}`,
      textAlign: 'left'
    },

    donation: {
      padding: `${rhythm(0.5)} ${rhythm(1)}`
    },

    amount: {
      fontWeight: 700,
      color: colors.secondary,
      whiteSpace: 'nowrap'
    },

    reply: {
      position: 'relative',
      padding: `${rhythm(0.5)} ${rhythm(1)}`,
      backgroundColor: colors.shade,

      ':before': {
        content: '',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        width: '3px',
        backgroundColor: colors.primary
      }
    },

    replyLabel: {
      color: colors.primary,
      fontSize: scale(-2),
      ...treatments.label
    }
  }
}
