import React from 'react'
import * as icons from './icons'

import Icon from 'constructicon/icon'

const CustomIcon = ({
  name,
  paths,
  ...props
}) => {
  const iconPaths = name ? icons[name] : paths

  if (iconPaths) {
    return (
      <Icon paths={iconPaths} {...props} />
    )
  } else {
    return (
      <Icon name={name} {...props} />
    )
  }
}

export default CustomIcon
