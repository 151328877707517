import React from 'react'
import { Link } from 'react-router'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Button from '../../../../../common/components/Button'
import ButtonGroup from 'constructicon/button-group'
import Container from 'constructicon/container'

const Header = ({
  application,
  classNames,
  donateUrl,
  loggedIn,
  styles
}) => (
  <header className={classNames.root}>
    <Container
      spacing={1}
      styles={styles.container}
      width={48}>
      <Link
        className={classNames.logo}
        to='/'>
        <img
          alt={application.application_title}
          src={application.logo_url}
        />
      </Link>
      <ButtonGroup
        align='right'
        spacing={0.5}>
        {loggedIn ? (
          <a
            className={classNames.link}
            href='/app'>
            Go to Profile
          </a>
        ) : (
          <a
            className={classNames.link}
            href='/'>
            Sign In
          </a>
        )}
        <Button
          href={donateUrl}
          tag='a'
          theme='primary'>
          Donate
        </Button>
      </ButtonGroup>
    </Container>
  </header>
)

export default withStyles(styles)(Header)
