export default (props, traits) => {
  const {
    mediaQuery,
    rhythm
  } = traits

  return {
    metrics: {
      margin: rhythm(-1),
      '> div': {
        margin: rhythm(1)
      },

      [mediaQuery('sm')]: {
        display: 'flex',
        flexWrap: 'wrap',

        '> div:last-child': {
          marginLeft: 'auto'
        }
      }
    },

    shareWrapper: {
      paddingTop: rhythm(1)
    }
  }
}
