export default (props, traits) => {
  const { colors, rhythm, mediaQuery } = traits

  return {
    root: {
      backgroundColor: colors.light,
      padding: rhythm(0.333),

      'img': {
        display: 'block',
        width: '100%'
      }
    },

    edit: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      whiteSpace: 'nowrap',
      bottom: rhythm(-0.5),
      [mediaQuery('xs')]: {
        bottom: rhythm(0.75)
      }
    }
  }
}
