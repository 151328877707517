export default (props, { colors, mediaQuery, rhythm, scale, transitions }) => ({
  root: {
    position: 'relative',
    margin: rhythm([1, -1]),
    padding: rhythm([0.75, 1]),
    fontSize: scale(-1.5),
    textAlign: 'center',
    color: '#77527A',
    backgroundColor: '#F9F0FA'
  },
  text: {
    marginRight: rhythm(0.25)
  },
  logo: {
    width: rhythm(5)
  },
  help: {
    position: 'absolute',
    top: '50%',
    right: rhythm(1),
    transform: 'translateY(-50%)',
    borderRadius: '50%',
    boxShadow: '0 0 0 2px rgba(0,0,0,0.25)',
    width: rhythm(1.125),
    height: rhythm(1.125),
    lineHeight: rhythm(1),
    [mediaQuery('xs', 'max-width')]: {
      display: 'none'
    }
  }
})
