export default (props, traits) => {
  const { colors, mediaQuery, rhythm } = traits

  return {
    root: {
      position: 'relative',
      marginBottom: rhythm(1),
      backgroundColor: colors.light
    },

    avatar: {
      position: 'relative',
      width: '45%',
      maxWidth: rhythm(10),
      margin: `${rhythm(-2)} auto 0`,

      [mediaQuery('md')]: {
        position: 'absolute',
        left: rhythm(2),
        bottom: rhythm(1),
        margin: 0
      }
    },

    content: {
      padding: rhythm(1),

      [mediaQuery('md')]: {
        paddingLeft: rhythm(13.5)
      }
    },

    grid: {
      textAlign: 'center',
      margin: rhythm(-1),

      '> *': {
        margin: rhythm(1)
      },

      [mediaQuery('md')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexPack: 'justify',
        textAlign: 'left'
      }
    },

    flex: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '> *': {
        margin: rhythm([0, 0.5])
      }
    }
  }
}
