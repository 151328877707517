export default (props, traits) => {
  const {
    rhythm
  } = traits

  return {
    root: {
      padding: `${rhythm(4)} 0`,
      textAlign: 'center'
    },

    label: {
      paddingTop: rhythm(1),
      opacity: 0.5
    }
  }
}
