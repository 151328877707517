import React, { Component } from 'react'
import get from 'lodash/get'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { signupUser } from '../../store/session'
import { withRouter, Link } from 'react-router'
import withToggle from 'constructicon/with-toggle'
import withForm from 'constructicon/with-form'
import form from './form'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Form from '../../../../../common/components/Form'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import InputField from '../../../../../common/components/InputField'
import LoadingMessage from '../../../../../common/components/LoadingMessage'
import Message from '../Message'
import TermsAndConditions from '../../../../../common/components/TermsAndConditions'

class SignupForm extends Component {
  constructor () {
    super()
    this.acceptSFRTerms = this.acceptSFRTerms.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = { errors: [] }
  }

  handleSubmit (e) {
    e.preventDefault()

    const { application, form, signupUser } = this.props

    return form
      .submit()
      .then(data =>
        signupUser({
          ...data,
          country: application.country_name
        })
      )
      .catch(error => {
        const errors = get(error, 'response.data.Errors', [])
          .map(err => err.ErrorMessage)
          .map(message => {
            switch (message) {
              case 'EmailAddress is in use.':
                return (
                  <span>
                    There is already an JustGiving account associated with
                    this email. If you do not remember your password you can{' '}
                    <Link to='/sign_in/reset_password'>reset it here</Link>.
                  </span>
                )
              default:
                return message
            }
          })

        return this.setState({ status: 'failed', errors })
      })
  }

  acceptSFRTerms () {
    const { onToggleOff, form } = this.props

    form.fields.sfrTerms.onChange(true)
    onToggleOff()
  }

  render () {
    const {
      application,
      form,
      formComponent,
      location,
      onToggleOff,
      status,
      toggled
    } = this.props

    const pwErrorStyles = {
      error: {
        '@media(min-width: 768px)': {
          width: form.fields.passwordConfirmation.error ? 'inherit' : 'calc(200% + 1.5rem)'
        }
      }
    }

    form.fields.password = form.fields.pField

    return (
      <LoadingMessage
        message='Creating your account, this may take a few seconds'
        status={status}>
        <Form
          isLoading={status === 'fetching'}
          onSubmit={this.handleSubmit}
          errors={this.state.errors}
          noValidate
          autoComplete='off'
          {...formComponent}
        >
          <Message show={location.query.message}>
            We have determined you do not have an existing JustGiving account, please create your account below.
          </Message>
          <Grid spacing={{ x: 0.5 }}>
            <GridColumn sm={6}>
              <InputField {...form.fields.firstName} />
            </GridColumn>
            <GridColumn sm={6}>
              <InputField {...form.fields.lastName} />
            </GridColumn>
          </Grid>

          <InputField {...form.fields.email} />
          <InputField {...form.fields.emailConfirmation} />

          <Grid spacing={{ x: 0.5 }}>
            <GridColumn sm={6}>
              <InputField {...form.fields.password} styles={pwErrorStyles} />
            </GridColumn>
            <GridColumn sm={6}>
              <InputField {...form.fields.passwordConfirmation} />
            </GridColumn>
          </Grid>

          <InputField {...form.fields.sfrTerms} />
          <InputField {...form.fields.jgTerms} />
          <InputField {...form.fields.newsletter} />
        </Form>
        {toggled && (
          <TermsAndConditions
            acceptTerms={this.acceptSFRTerms}
            handleClose={onToggleOff}
            currentApplication={application}
          />
        )}
      </LoadingMessage>
    )
  }
}

const mapState = ({ config, session }) => ({
  ...config,
  email: session.email,
  status: session.status
})

const mapActions = { signupUser }

export default compose(
  connect(mapState, mapActions),
  withRouter,
  withToggle,
  withStyles(styles),
  withForm(form)
)(SignupForm)
