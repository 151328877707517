export default (props, { colors, rhythm, scale }) => ({
  root: {
    textAlign: 'center'
  },
  heading: {
    fontWeight: 'normal',
    fontSize: scale(-1),
    letterSpacing: '0.025rem'
  },
  form: {
    root: {
      position: 'relative'
    },

    submit: {
      padding: rhythm([0.5, 1]),
      backgroundColor: colors.justgiving,
      borderWidth: 0,
      fontSize: scale(0)
    }
  },
  footer: {
    padding: rhythm([1, 1, 0.25]),
    fontSize: scale(-0.5),

    a: {
      color: 'inherit',
      textDecoration: 'underline'
    }
  }
})
