import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'

import flash from './flash'
import session from './session'

const middlewares =
  process.env.NODE_ENV === 'production' ? [thunk] : [thunk, createLogger()]

export default (props = {}) =>
  createStore(
    combineReducers({
      config: (state = {}) => state,
      flash,
      session
    }),
    {config: props},
    applyMiddleware(...middlewares)
  )
