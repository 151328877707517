import axios from 'axios'

const headers = typeof document !== 'undefined' ? {
  Accept: 'application/json',
  'X-CSRF-Token': document.querySelectorAll('meta[name="csrf-token"]')[0]
    .content
} : {}

const instance = axios.create({
  headers
})

const handleRequest = promise =>
  promise
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))

export const fetch = (url, params) =>
  handleRequest(instance.get(`${url}.json`, { params }))

export const put = (url, params = {}) =>
  handleRequest(instance.put(`${url}.json`, params))

export const post = (url, params = {}) =>
  handleRequest(instance.post(`${url}.json`, params))

export const destroy = url => handleRequest(instance.delete(`${url}.json`))

export const patch = (url, params = {}) =>
  handleRequest(instance.patch(`${url}.json`, params))

export default fetch
