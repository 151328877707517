import axios from 'axios'

function paramsSerializer(params) {
  return Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
}

const servicesAPI = axios.create({
  baseURL: "https://api.blackbaud.services",
  paramsSerializer: function(params) {
    return paramsSerializer(params);
  }
});

export function updateBaseURL(url) {
  servicesAPI.defaults.baseURL = url
}

export const signIn = data =>
  servicesAPI.post('/v1/justgiving/iam/login', data)
    .then(response => response.data)

export const signUp = data =>
  servicesAPI.post('/v1/justgiving/iam/register', data)
    .then(response => response.data)

export const resetPassword = data =>
  servicesAPI.post('/v1/justgiving/iam/reset-password', data)

export const checkAccountAvailability = email =>
  servicesAPI.get(`/v1/account/${email}`)
