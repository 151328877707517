import React from 'react'
import moment from 'moment'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const DateDisplay = ({
  label,
  date,
  showYear,
  classNames
}) => {
  const momentDate = moment(date, 'YYYY-MM-DD')
  const month = momentDate.format('MMM')
  const day = momentDate.format('DD')

  return momentDate.isValid() ? (
    <div className={classNames.date}>
      <div className={classNames.label}>{label}</div>
      <div className={classNames.calendar}>
        <div className={classNames.month}>{month}</div>
        <div className={classNames.day}>{day}</div>
        {showYear && <div className={classNames.year}>{momentDate.format('YYYY')}</div>}
      </div>
    </div>
  ) : null
}

DateDisplay.defaultProps = {
  showYear: false
}

export default withStyles(styles)(DateDisplay)
