import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Avatar from '../../../../../common/components/Avatar'
import Banner from '../../../../../common/components/Banner'
import Button from '../../../../../common/components/Button'
import ButtonGroup from 'constructicon/button-group'
import DateDisplay from '../../../../../common/components/DateDisplay'
import Heading from 'constructicon/heading'
import ShareButton from '../../../../../common/components/ShareButton'
import ThickHeading from '../../../../../common/components/ThickHeading'

const ChildHeader = ({ child, donateUrl, classNames }) => {
  const {
    beneficiary = {},
    event = {},
    received_child_stickers: childStickers
  } = child

  return (
    <header className={classNames.root}>
      <Banner
        banner={child.banner_url}
        childStickers={childStickers}
      />
      <div className={classNames.avatar}>
        <Avatar child={child} />
      </div>
      <div className={classNames.content}>
        <div className={classNames.grid}>
          <div>
            <ThickHeading spacing={{ b: 0.25 }}>
              {child.firstname}
            </ThickHeading>
            <Heading
              size={0}
              spacing={{ b: 1 }}
              tag='h6'>
              {beneficiary.name}
            </Heading>
            <ButtonGroup>
              <Button
                href={donateUrl}
                tag='a'
                theme='primary'>
                Donate Now
              </Button>
              <ShareButton
                url={child.public_url}>
                Share Page
              </ShareButton>
            </ButtonGroup>
          </div>
        </div>
      </div>
    </header>
  )
}

export default withStyles(styles)(ChildHeader)
