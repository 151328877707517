import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import Icon from '../Icon'

const StateEmpty = ({
  classNames,
  message = 'No data to display',
  styles
}) => (
  <div className={classNames.root}>
    <Icon name='info' size={3} />
    <div className={classNames.label}>{message}</div>
  </div>
)

export default withStyles(styles)(StateEmpty)
