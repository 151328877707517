export default (props, { colors, mediaQuery }) => ({
  root: {
    width: '50%',
    padding: '0.5em',
    [mediaQuery('xxs')]: {
      width: '33%'
    },
    [mediaQuery('xs')]: {
      width: '25%'
    },
    [mediaQuery('sm')]: {
      width: '16.6%'
    }
  },
  image: {
    transition: 'all 0.4s ease',
    ':hover': {
      transform: 'scale(1.05) rotate(3deg)'
    }
  },
  statusText: {
    marginBottom: '0.5em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '0.8em',
    color: colors.primary
  },
  text: {
    marginTop: '1em',
    lineHeight: '1.2em',
    fontWeight: 'bold',
    fontSize: '0.8em',
    textAlign: 'center'
  }
})
