import merge from 'lodash/merge'

export default (props, traits) => {
  const { colors, rhythm, scale } = traits

  const baseStyles = {
    container: {
      marginTop: rhythm(1)
    },

    content: {
      display: 'flex',
      alignItems: 'center',
      justtifyContent: 'space-between',
      margin: rhythm(-0.5),
      '> *': {
        margin: rhythm(0.5)
      }
    },

    label: {
      fontWeight: 600,
      marginBottom: rhythm(0.5)
    },

    bar: {
      position: 'relative',
      flex: 1,
      height: rhythm(1.5),
      backgroundColor: colors.secondaryLighter,
      color: colors.light
    },

    progress: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      backgroundColor: colors.secondary
    },

    percentage: {
      position: 'absolute',
      top: '50%',
      left: rhythm(1),
      transform: 'translateY(-50%)',
      fontWeight: 900,
      fontSize: scale(1)
    },

    remaining: {
      position: 'absolute',
      top: '50%',
      right: rhythm(1),
      transform: 'translateY(-50%)',
      fontWeight: 700,
      textTransform: 'uppercase'
    },

    goal: {
      fontSize: scale(3),
      fontWeight: 900
    }
  }

  return merge(baseStyles, props.styles)
}
