export default (props, traits) => {
  const { calculateSpacing } = traits
  const { margin } = props

  return {
    root: {
      ...calculateSpacing(margin, 'margin')
    }
  }
}
