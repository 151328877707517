import React from 'react'

import AuthForm from '../../components/AuthForm'
import { Link } from 'react-router'
import ResetPasswordForm from '../../components/ResetPasswordForm'

const ResetPassword = () => (
  <AuthForm
    heading='Reset Your JustGiving Password'
    formComponent={ResetPasswordForm}
    footer={<Link to='/sign_up'>Need an account?</Link>}
  />
)

export default ResetPassword
