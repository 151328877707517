import React from 'react'
import withStyles from 'constructicon/with-styles'
import isFunction from 'lodash/isFunction'
import styles from './styles'
import auTerms from './terms/au'
import nzTerms from './terms/nz'

import Button from '../Button'
import ButtonGroup from 'constructicon/button-group'
import Icon from 'constructicon/icon'
import Markdown from '../Markdown'
import Modal from '../Modal'
import Section from '../Section'

const TermsAndConditions = ({
  acceptTerms,
  classNames,
  currentApplication,
  handleClose,
  styles
}) => (
  <Modal
    contentLabel='Terms modal'
    onRequestClose={handleClose}
    isOpen
    spacing={0}
    closeIcon={isFunction(handleClose) ? <Icon name='close' /> : false}
    shouldCloseOnOverlayClick={isFunction(handleClose)}
  >
    <Section styles={styles.section} heading='Terms and Conditions'>
      <div className={classNames.termsWrapper}>
        <Markdown
          markdownString={
            currentApplication.app_uid === 'school-au' ? auTerms : nzTerms
          }
        />
      </div>
      <ButtonGroup>
        <Button
          onClick={acceptTerms}
          theme='primary'
          size={-1}
          spacing={{ y: 0.25, x: 0.5 }}
        >
          Accept Terms
        </Button>
      </ButtonGroup>
    </Section>
  </Modal>
)

export default withStyles(styles)(TermsAndConditions)
