export default (props, traits) => {
  const {
    danger
  } = props

  const {
    colors,
    rhythm,
    transitions
  } = traits

  return {
    wrapper: {
      position: 'fixed',
      top: rhythm(1),
      left: '50%',
      transform: 'translateX(-50%)',
      margin: 0,
      zIndex: 200
    },

    root: {
      maxWidth: '100%',
      width: rhythm(14),
      padding: `${rhythm(0.25)} ${rhythm(0.5)}`,
      textAlign: 'center',
      cursor: 'pointer',
      color: colors.light,
      backgroundColor: danger ? colors.danger : colors.success,
      transition: transitions.easeOut,
      boxShadow: '0 2px 5px rgba(0,0,0,0.25)'
    },

    active: {
      transform: 'translateY(0)',
      opacity: 1
    },

    hidden: {
      transform: `translateY(${rhythm(-3)})`,
      opacity: 0
    }
  }
}
