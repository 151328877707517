import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import { Link } from 'react-router'

const Tabs = ({ classNames }) => (
  <nav className={classNames.root}>
    <Link
      className={classNames.link}
      activeClassName={classNames.active}
      to='/sign_up'
    >
      Register
    </Link>
    <Link
      className={classNames.link}
      activeClassName={classNames.active}
      to='/sign_in'
    >
      Login
    </Link>
  </nav>
)

export default withStyles(styles)(Tabs)
