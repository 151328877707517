import merge from 'lodash/merge'

export default (props, traits) => {
  const {
    colors,
    fonts,
    measures,
    rhythm,
    scale
  } = traits

  const {
    error,
    readOnly,
    styles
  } = props

  const baseStyles = {
    field: {
      display: 'block',
      fontFamily: fonts.body,
      color: colors.dark,
      marginBottom: rhythm(1)
    },

    label: {
      display: 'block',
      fontWeight: 700,
      fontSize: scale(-2),
      lineHeight: measures.medium,
      textAlign: 'left',
      marginBottom: rhythm(0.25)
    },

    required: {
      display: 'inline-block',
      color: colors.red
    },

    input: {
      width: '100%',
      textAlign: 'left',
      backgroundColor: colors.light,
      color: readOnly ? colors.lightGrey : colors.dark,
      padding: `${rhythm(0.125)} ${rhythm(0.333)}`,
      height: rhythm(1.666),
      border: `thin solid ${error ? colors.danger : colors.lightGrey}`,
      borderRadius: '0.125rem',
      ':focus': {
        borderColor: error ? colors.red : colors.secondary,
        boxShadow: `0 0 5px ${error ? colors.danger : colors.secondary}`
      }
    },

    error: {
      fontSize: scale(-0.75),
      fontWeight: '900',
      marginTop: rhythm(0.5),
      color: colors.red
    }
  }

  return merge(baseStyles, styles)
}
