import React from 'react'
import * as traits from '../../traits'

import TraitsProvider from 'constructicon/traits-provider'

export const withTraits = Component => {
  class TraitsWrapper extends React.Component {
    render () {
      return (
        <TraitsProvider traits={traits}>
          <Component {...this.props} />
        </TraitsProvider>
      )
    }
  }

  return TraitsWrapper
}

export const mapTraits = components =>
  Object.keys(components).reduce(
    (acc, key) => ({
      ...acc,
      [key]: withTraits(components[key])
    }),
    {}
  )

export default withTraits
