import React from 'react'
import snarkdown from 'snarkdown'
import RichText from 'constructicon/rich-text'

const Markdown = ({ markdownString }) => (
  <RichText>
    {snarkdown(markdownString)}
  </RichText>
)

export default Markdown
