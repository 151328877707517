import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import Button from 'constructicon/button'

const CustomButton = ({
  classNames,
  styles,
  theme = 'transparentDark',
  ...props
}) => {
  const themes = {
    transparent: {
      background: 'transparent',
      foreground: 'dark',
      borderColor: 'transparent'
    },
    transparentDark: {
      background: 'transparent',
      foreground: 'dark',
      borderColor: 'dark'
    },
    transparentDarkGrey: {
      background: 'transparent',
      foreground: 'darkGrey',
      borderColor: 'darkGrey'
    },
    transparentPrimary: {
      background: 'transparent',
      foreground: 'primary',
      borderColor: 'primary'
    },
    transparentSecondary: {
      background: 'transparent',
      foreground: 'secondary',
      borderColor: 'secondary'
    },
    transparentLight: {
      background: 'transparent',
      foreground: 'light',
      borderColor: 'light'
    },
    lightPrimary: {
      background: 'light',
      foreground: 'primary',
      borderColor: 'primary'
    },
    primary: {
      background: 'primary',
      foreground: 'light',
      borderColor: 'primary'
    },
    secondary: {
      background: 'secondary',
      foreground: 'light',
      borderColor: 'secondary'
    },
    lightSecondary: {
      background: 'light',
      foreground: 'secondary',
      borderColor: 'light'
    },
    light: {
      background: 'light',
      foreground: 'dark',
      borderColor: 'light'
    }
  }

  const disabledOnClick = (e) => e.preventDefault()

  return (
    <Button
      borderWidth={2}
      styles={styles.root}
      onClick={props.disabled ? disabledOnClick : props.onClick}
      {...themes[theme]}
      {...props}
    />
  )
}

export default withStyles(styles)(CustomButton)
