import React from 'react'
import UAParser from 'ua-parser-js'
import Button from '../../Button'
import Icon from '../../Icon'

const ButtonSMS = ({ url, message, icon = 'chat', ...props }) => {
  const seperator = () => {
    const parser = new UAParser()
    const { os } = parser.getResult()

    switch (os.name) {
      case 'Mac OS':
        return ';&'
      case 'iOS':
        return os.version.split('.')[0] < 8 ? ';' : '&'
      default:
        return '?'
    }
  }

  const body = encodeURIComponent(`${message} — ${url}`)

  return (
    <Button
      background='primary'
      borderColor='primary'
      borderWidth={2}
      effect='grow'
      foreground='light'
      href={`sms:${seperator()}body=${body}`}
      spacing={0.5}
      tag='a'
      {...props}>
      <Icon name={icon} />
    </Button>
  )
}

export default ButtonSMS
