import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Loading from '../Loading'

const StateLoading = ({
  classNames,
  children = 'Loading Data'
}) => (
  <div className={classNames.root}>
    <Loading />
    <div className={classNames.label}>{children}</div>
  </div>
)

export default withStyles(styles)(StateLoading)
