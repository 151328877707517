import React from 'react'
import numbro from 'numbro'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const Progress = ({
  target,
  raised = 0,
  classNames,
  styles
}) => {
  if (!target) {
    return null
  }

  const percentage = `${Math.min(100, Math.round(raised / target * 100))}%`

  return (
    <div className={classNames.container}>
      <div className={classNames.label}>Your Goal</div>
      <div className={classNames.content}>
        <div className={classNames.bar}>
          <span className={classNames.progress} style={{ width: percentage }} />
          <div className={classNames.percentage}>{percentage}</div>
          <div className={classNames.remaining}>
            {`$${Math.max(0, Math.ceil((target - raised) / 100))} to go`}
          </div>
        </div>
        <div className={classNames.goal}>
          {numbro(target / 100).format('$0')}
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(Progress)
