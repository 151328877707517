export default (props, traits) => {
  const { rhythm } = traits

  return {
    container: {
      root: {
        textAlign: 'center',
        paddingTop: rhythm(0)
      }
    },

    sponsor: {
      display: 'inline-block',
      padding: rhythm(0.6667)
    },

    logo: {
      display: 'block',
      height: rhythm(1.6667)
    }
  }
}
