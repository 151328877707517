import * as validators from 'constructicon/lib/validators'

export default props => ({
  fields: {
    email: {
      label: 'Email Address',
      type: 'email',
      placeholder: 'email@example.com',
      required: true,
      validators: [
        validators.email('Please enter a valid email address'),
        validators.required('Please enter your email address')
      ]
    }
  }
})
