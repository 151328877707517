import React from 'react'
import styles from './styles'
import withStyles from 'constructicon/with-styles'
import inProgress from './in-progress.png'
import Tooltip from '../../../../../common/components/Tooltip'

const Badge = ({
  classNames,
  description,
  image = {},
  isAchieved,
  name,
  styles
}) => {
  const TooltipInner = () => (
    <div>
      <div className={classNames.statusText}>
        {isAchieved ? 'achieved' : 'in progress'}
      </div>
      <div>
        {description}
      </div>
    </div>
  )

  return (
    <div className={classNames.root}>
      <Tooltip
        info={<TooltipInner />}
        noBackground>
        <div>
          <img
            alt={name}
            className={classNames.image}
            src={isAchieved ? image.url : inProgress}
          />
        </div>
        <div className={classNames.text}>
          {name}
        </div>
      </Tooltip>
    </div>
  )
}

export default withStyles(styles)(Badge)
