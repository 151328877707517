import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const Blocks = ({
  children,
  classNames
}) => (
  <div className={classNames.root}>
    {children}
  </div>
)

export default withStyles(styles)(Blocks)
