import React from 'react'
import find from 'lodash/find'
import styles from './styles'
import withStyles from 'constructicon/with-styles'

import Badge from '../Badge'
import Section from '../../../../../common/components/Section'

const Badges = ({
  achievedBadges,
  badges,
  classNames,
  styles
}) => (
  <Section background='secondary' heading='Badges' dark>
    <div className={classNames.badgeList}>
      {badges.map(badge => (
        <Badge
          description={badge.description}
          image={badge.image}
          isAchieved={find(achievedBadges, achieved => achieved.badge.id === badge.id)}
          key={badge.id}
          name={badge.name}
        />
      ))}
    </div>
  </Section>
)

export default withStyles(styles)(Badges)
