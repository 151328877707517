import React, { Component } from 'react'
import copy from 'copy-to-clipboard'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Button from '../../../common/components/Button'
import Icon from '../../../common/components/Icon'

class CopyUrl extends Component {
  constructor () {
    super()
    this.copyUrl = this.copyUrl.bind(this)
    this.selectAll = this.selectAll.bind(this)

    this.state = {
      copied: false
    }
  }

  copyUrl () {
    const { onClick, url } = this.props
    copy(url) && this.setState({ copied: true })
    onClick && onClick()
  }

  selectAll () {
    this.refs.input.select()
  }

  render () {
    const {
      url,
      classNames
    } = this.props

    const { copied } = this.state

    return (
      <div className={classNames.root}>
        <input
          className={classNames.input}
          onClick={this.selectAll}
          readOnly
          ref='input'
          value={url}
        />
        <Button onClick={this.copyUrl} size={-1}>
          <Icon name={copied ? 'check' : 'link'} />
          <span>{copied ? 'Copied!' : 'Copy'}</span>
        </Button>
      </div>
    )
  }
}

export default withStyles(styles)(CopyUrl)
